import styled from "styled-components";
import { React } from 'react';

import Header from "./HomepageComponents/Header";
import Navigation from "./HomepageComponents/Navigation";
import ChewieCoin from "./HomepageComponents/ChewieCoin";
import Buy from "./HomepageComponents/Buy";
import Community from "./HomepageComponents/Community";
import Meet from "./HomepageComponents/Meet";

const Background = styled.section`
  width: 100%;
`;

const Homepage = () => {

    return (
        <Background>
            <Navigation/>
            <Header/>
            <Community/>
            <ChewieCoin/>
            <Buy/>
            <Meet/>
        </Background>
    );
};

export default Homepage;