import React from 'react';
import styled from "styled-components";
import logo from '../../assets/img/chewie.png'

const Background = styled.section`
  width: 100%;
  background-color: #fff8e7;
  overflow:auto;
  padding-bottom: 50px;
`;

const TextHolder = styled.div`
  width: 47%;
  float: left;
  padding-left: 75px;
  padding-top: 225px;
  @media only screen and (max-width: 1225px) {
    padding-left: 25px;
    width: 45%;
  }
  @media only screen and (max-width: 1225px) {
    width: 53%;
  }
  @media only screen and (max-width: 670px) {
    width: 46%;
    }
  @media only screen and (max-width: 550px) {
    width: 90%;
    padding-top: 10px;
    }
`;

const MobileLogo = styled.img`
    display: none; 
    min-width: 350px;
    @media only screen and (max-width: 550px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        padding-top: 35px;
    }
`

const Logo = styled.img`
    width: 37%;
    padding-top: 30px;
    float: left;
    min-width: 200px;
    padding-left: 35px;
    @media only screen and (max-width: 1225px) {
        padding-top: 70px;
        width: 40%;
    }
    @media only screen and (max-width: 1100px) {
        padding-top: 10%;
        width: 42%;
    }
    @media only screen and (max-width: 1100px) {
        padding-top: 13%;
        width: 40%;
    }
    @media only screen and (max-width: 875px) {
        padding-top: 20%;
        width: 38%;
    }
    @media only screen and (max-width: 710px) {
        padding-top: 25%;
        width: 37%;
    }
    @media only screen and (max-width: 550px) {
        padding-top: 40%;
        width: 48%;
    }
    @media only screen and (max-width: 550px) {
        display: none;
    }
`

const MainText = styled.p`
    font-size: 45px;
    margin: 0px;
    font-weight: bold;
    color: black;
    font-family: 'Bree Serif', serif;
    @media only screen and (max-width: 1225px) {
        font-size: 40px;
    }
    @media only screen and (max-width: 915px) {
          font-size: 35px;
    }
    @media only screen and (max-width: 700px) {
          font-size: 25px;
    }
`

const Description = styled.p`
    font-size: 25px;
    margin: 0px;
    color: black;
    font-family: 'Bree Serif', serif;
    @media only screen and (max-width: 1225px) {
        font-size: 20px;
    }
    @media only screen and (max-width: 915px) {
          font-size: 17px;
    }
`

const LinkButton = styled.button`
    font-family: 'Bree Serif', serif;
    background-color: #fff8e7;
    border-style: solid;
    border-radius: 10px;
    border-color: black;
    color: black;
    padding: 15px 35px;
    margin-top: 10px;
    margin-right: 1%;
    font-size: 25px;
    font-weight: bold;
    float: left;
    transition-duration: 0.4s;
    cursor: pointer;

    &:hover {
      background-color: black;
      color: white;
    }

    @media only screen and (max-width: 620px) {
        padding: 5px 18px;
        font-size: 22px;
    }
`

const Header = () => {
    return (
        <Background id="header">
            <MobileLogo src={logo} alt="logo"/>
            <TextHolder>
            <MainText>Woof woof! Chewie Coin is a Decentralized ERC-20 Token</MainText>
            <Description>With the aim of supporting our favorite pets all around the world!</Description>
            <a href="https://app.uniswap.org/swap?outputCurrency=0x276240D82d0b595EDa6C3c345d01931458a02CC1&inputCurrency=ETH" target="_blank" rel="noreferrer"><LinkButton>Buy</LinkButton></a>
            <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x0e971a1ce8ef6b31ba9a3eb413bedeb28b7431f8" target="_blank" rel="noreferrer"><LinkButton>Chart</LinkButton></a>
            </TextHolder>
            <Logo src={logo} alt="logo"/>
        </Background>
    );
};

export default Header;