import styled from "styled-components";
import telegram from '../../assets/img/telegram.png'
import twitter from '../../assets/img/twitter.png'
import reddit from '../../assets/img/reddit.png'

const Background = styled.section`
  width: 100%;
  background-color: #fff8e7;
  overflow:hidden;
  padding-bottom: 50px;
`;

const MainText = styled.p`
    font-weight: bold;
    padding-top: 25px;
    font-size: 50px;
    margin: 0px;
    text-align: center;
    color: black;
    font-family: 'Bree Serif', serif;
    clear: both;
`

const DescriptionHolder = styled.div`
    padding-left: 75px;
    padding-right: 75px;
    padding-top: 65px;
    width: 50%;
    float: left;
    @media only screen and (max-width: 1465px) {
        width: 100%;
        float: none;
        padding-top: 25px;
        padding-left:  0px;
        padding-right: 0px;
    }
`

const Description = styled.p`
    font-size: 25px;
    margin: 0px;
    color: black;
    font-family: 'Bree Serif', serif;
    padding-bottom: 25px;
    @media only screen and (max-width: 1465px) {
        padding-right: 15px;
        padding-left: 15px;
        text-align: center;
    }
`

const SocialMediaHolder = styled.div`
    float: left;
    width: 37%;
    @media only screen and (max-width: 1465px) {
        width: 100%;
        float: none;
        text-align: center;
    }
`

const SocialMedia = styled.img`
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 75px;
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    :hover {
        opacity: 1;
    }
    @media only screen and (max-width: 1465px) {
        padding-top: 20px;
    }
`

const Community = () => {
    return (
        <Background id="community">
            <MainText>Community</MainText>
            <DescriptionHolder>
                <Description>Join the Chewie Coin community through any (or all) of our social media pages.</Description>
                <Description>Feel free to use our community boards to discuss Chewie Coin, get up-to-date information and updates, and talk to a pet-loving community about your best friend!</Description>
            </DescriptionHolder>
            <SocialMediaHolder>
                <a href="https://twitter.com/Chewie_Coin" target="_blank" rel="noreferrer"><SocialMedia src={twitter} alt="twitter"/></a>
                <a href="https://t.me/ChewieCoinCommunity" target="_blank" rel="noreferrer"><SocialMedia src={telegram} alt="telegram"/></a>
                <a href="https://www.reddit.com/r/Chewie_Coin/" target="_blank" rel="noreferrer"><SocialMedia src={reddit} alt="reddit"/></a>
            </SocialMediaHolder>
        </Background>
    );
};

export default Community;