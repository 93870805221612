import React from 'react';
import styled from "styled-components";

const Background = styled.section`
  width: 100%;
  background-color: #fff8e7;
  overflow:auto;
`;

const MainText = styled.p`
    font-weight: bold;
    padding-top: 25px;
    font-size: 50px;
    margin: 0px;
    text-align: center;
    color: black;
    font-family: 'Bree Serif', serif;
`

const Description = styled.p`
    font-size: 25px;
    margin: 0px;
    text-align: center;
    color: black;
    font-family: 'Bree Serif', serif;
    padding-bottom: 25px;
    @media only screen and (max-width: 1100px) {
        padding-left: 10px;
        padding-right: 10px;
    }
`

const StepNumber = styled.div`
    display: block;
    height: 80px;
    width: 80px;
    line-height: 80px;
    margin: auto;

    -moz-border-radius: 20px;
    border-radius: 20px;

    background-color: black;
    color: white;
    text-align: center;
    font-size: 60px;
    font-weight: bold;
    font-family: 'Bree Serif', serif;
`

const StepTitle = styled.p`
    font-size: 40px;
    font-weight: bold;
    margin: 0px;
    text-align: center;
    color: black;
    font-family: 'Bree Serif', serif;
`

const StepInfo = styled.p`
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    font-size: 25px;
    margin: 0px;
    text-align: center;
    color: black;
    font-family: 'Bree Serif', serif;
`

const StepInfoLink = styled.a`
    font-size: 25px;
    color: black;
    font-family: 'Bree Serif', serif;
`

const StepHolder = styled.div`
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 75%;
    min-height: 300px;
    background-color: #987654;
    margin: auto;
    margin-bottom: 25px;
    opacity: 0.8;
    transition: 0.3s;
    :hover {
        opacity: 1;
    }
    @media only screen and (max-width: 1100px) {
        width: 90%;
    }
`

const LastStepHolder = styled.div`
    border-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 75%;
    min-height: 300px;
    background-color: #987654;
    margin: auto;
    opacity: 0.8;
    transition: 0.3s;
    :hover {
        opacity: 1;
    }
    @media only screen and (max-width: 1100px) {
        width: 90%;
    }
`

const Buy = () => {
    return (
        <Background>
            <MainText id="buy">How to buy</MainText>
            <Description>Chewie Coin can be purchased through UniSwap using Ethereum and will soon be available on many more exchanges.</Description>
            <MainText>Guide</MainText>
            <Description>For those unfamiliar with aquiring tokens, please feel free to follow the below steps to get some Chewie Coin!</Description>
            <StepHolder>
                <StepNumber>1</StepNumber>
                <StepTitle>Create a wallet with Coinbase, MetaMask, or Uniswap</StepTitle>
                <StepInfo>Create a wallet on your computer or mobile device using one of many online wallet services (<StepInfoLink href="https://www.coinbase.com/wallet" target="_blank">Coinbase Wallet</StepInfoLink>, <StepInfoLink href="https://metamask.io" target="_blank">MetaMask</StepInfoLink>, <StepInfoLink href="https://wallet.uniswap.org/" target="_blank">Uniswap Wallet</StepInfoLink>). This wallet will hold both your Ethereum and Chewie Coin and will allow you to send, recieve, and trade.</StepInfo>
            </StepHolder>
            <StepHolder>
                <StepNumber>2</StepNumber>
                <StepTitle>Get some Ethereum and transfer it to your wallet</StepTitle>
                <StepInfo>You can now buy Ethereum directly through your selected wallet service or transfer it into your wallet from an exchange such as Coinbase.</StepInfo>
            </StepHolder>
            <StepHolder>
                <StepNumber>3</StepNumber>
                <StepTitle>Connect your wallet to Uniswap</StepTitle>
                <StepInfo>Now that you have Ethereum in your wallet, head over to Uniswap (<StepInfoLink href="https://app.uniswap.org/swap" target="_blank">https://app.uniswap.org/swap</StepInfoLink>). On Uniswap select "Connect" at the top right and then connect your wallet to Uniswap.</StepInfo>
            </StepHolder>
            <LastStepHolder>
                <StepNumber>4</StepNumber>
                <StepTitle>Trade your Ethereum for Chewie Coin!</StepTitle>    
                <StepInfo>Press "Select a token" on Uniswap and search for Chewie Coin (CHEWIE). You can now exchange your Ethereum for Chewie Coin, welcome to the club, woof woof!</StepInfo>
            </LastStepHolder>
        </Background>
    );
};

export default Buy;