import React from 'react';
import styled from "styled-components";
import coin from '../../assets/img/coin.png'

const Coin = styled.img`
    width: 450px;
    float: left;
    @media only screen and (max-width: 1525px) {
        float: none;
        width: 90%;
        margin-bottom: -15px;
    }
`

const DescriptionHolder = styled.div`
    padding-top: 65px;
    width: 70%;
    float: left;
    @media only screen and (max-width: 1525px) {
        float: none;
        padding-top: 0px;
        width: 100%;
    }
`

const Background = styled.section`
    width: 100%;
    background-color: #fff8e7;
    overflow: hidden;
    @media only screen and (max-width: 1525px) {
        text-align: center;
    }
`;

const MainText = styled.p`
    margin: 0;
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    color: black;
    font-family: 'Bree Serif', serif;
`

const Description = styled.p`
    font-size: 25px;
    color: black;
    font-family: 'Bree Serif', serif;
    @media only screen and (max-width: 1525px) {
        padding-left: 25px;
        padding-right: 25px;
    }
`

const ChewieCoin = () => {
    return (
        <Background id="chewiecoin">
            <MainText>What is Chewie Coin?</MainText>
            <Coin src={coin} alt="logo"/>
            <DescriptionHolder>
                <Description>Chewie Coin is simply a DeFi ERC-20 token representing our four-legged friends with our mascot Chewie the Chihuhua.</Description>
                <Description>Chewie Coin is meant to be used for all of your pet-friendly needs with the aim of growing to the goal of helping our fluffy (or not so fluffy) friends along the way.</Description>
            </DescriptionHolder>
        </Background>
    );
};

export default ChewieCoin;