import React from 'react';
import styled from "styled-components";
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

import one from '../../assets/img/gallery/img/1.JPG'
import two from '../../assets/img/gallery/img/2.JPG'
import three from '../../assets/img/gallery/img/3.JPG'
import four from '../../assets/img/gallery/img/4.JPG'
import five from '../../assets/img/gallery/img/5.JPG'
import six from '../../assets/img/gallery/img/6.JPG'
import seven from '../../assets/img/gallery/img/7.JPG'
import eight from '../../assets/img/gallery/img/8.JPG'
import nine from '../../assets/img/gallery/img/9.JPG'
import ten from '../../assets/img/gallery/img/10.JPG'
import eleven from '../../assets/img/gallery/img/11.JPG'
import twelve from '../../assets/img/gallery/img/12.JPG'

const Background = styled.section`
  width: 100%;
  background-color: #fff8e7;
  overflow:auto;

`;

const MainText = styled.p`
    font-weight: bold;
    padding-top: 25px;
    font-size: 50px;
    margin: 0px;
    text-align: center;
    color: black;
    font-family: 'Bree Serif', serif;
`

const Description = styled.p`
    text-align: center;
    padding-left: 75px;
    padding-right: 75px;
    font-size: 25px;
    margin: 0px;
    color: black;
    font-family: 'Bree Serif', serif;
    padding-bottom: 5px;
    @media only screen and (max-width: 1465px) {
        padding-right: 15px;
        padding-left: 15px;
    }
`

const Padded = styled.div`
    padding-bottom: 35px;
`

const Meet = () => {
    const images = [
        {
            original: one,
        },
        {
            original: two,
        },
        {
            original: three,
        },
        {
            original: four,
        },
        {
            original: five,
        },
        {
            original: six,
        },
        {
            original: seven,
        },
        {
            original: eight,
        },
        {
            original: nine,
        },
        {
            original: ten,
        },
        {
            original: eleven,
        },
        {
            original: twelve,
        }
    ]
    return (
        <Background id="meet">
            <MainText>Meet Chewie the Chihuahua</MainText>
            <Description>Although our logo is a pretty amazing and an accurate depiction of Chewie, there's so much more to this lovable doggo.</Description>
            <Description>Below are some images of Chewie in action, with more to be added as time goes on!</Description>
            <Padded/>
            <ImageGallery showPlayButton={false} items={images} showBullets={true} showThumbnails={false}/>
        </Background>
    );
};

export default Meet;