import React, { useState } from 'react';
import styled from "styled-components";
import {Link} from 'react-scroll'

import coin from '../../assets/img/coin.png'
import paw from '../../assets/img/paw.png'

const Background = styled.section`
  width: 100%;
  height: 80px;
  background-color: #987654;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const Coin = styled.img`
    float: left;
    width: 80px;
    padding-top: 5px;
    padding-left: 5px;
    :hover {
        cursor: pointer;
    }
`

const MainText = styled.p`
    padding-top: 3px;
    margin: 0;
    float: left;
    font-size: 50px;
    font-style: bold;
    color: white;
    font-family: 'Bree Serif', serif;
    @media only screen and (max-width: 7000px) {
        font-size: 40px;
        padding-top: 10px;
    }
    :hover {
        cursor: pointer;
    }
`
const LinkHolder = styled.div`
  @media only screen and (max-width: 1150px) {
        display: none;
    }
`;

const NavLink = styled.p`
    padding-top: 25px;
    padding-right: 30px;
    margin: 0;
    float: right;
    font-size: 20px;
    font-style: bold;
    color: white;
    font-family: 'Bree Serif', serif;
    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

const Paw = styled.img`
    float: right;
    width: 35px;
    padding-top: 21px;
    padding-right: 5px;
`

const HamburgerHolder = styled.div`
    display: none;
    @media only screen and (max-width: 1150px) {
        display: block;
    }
`;

const HamburgerButton = styled.p`
    padding-right: 20px;
    line-height: 70px;
    margin: 0;
    float: right;
    font-size: 50px;
    font-style: bold;
    color: white;
    font-family: 'Bree Serif', serif;
    :hover {
        cursor: pointer;
    }
`
const Hamburger = styled.div`
    background-color: #987654;
    height: 100vh;
    clear: both;
    padding-top: 50px;
`;

const HamburgerLinkHolder = styled.div`
    display: inline-block;
    padding-bottom: 30px;
    padding-left: 15px;
    width: 100%;
`
const HamburgerLink = styled.a`
    font-size: 7vw;
    font-style: bold;
    color: white;
    font-family: 'Bree Serif', serif;
    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

const PawHamburger = styled.img`
    padding-right: 15px;
    width: 6vw;
`

const Navigation = () => {
    const [hamburger, setHamburger] = useState("☰");
    return (
        <Background>
            <Link to="header" offset={-50} smooth={true}><Coin src={coin} alt="logo"/></Link>
            <MainText><Link to="header" offset={-50} smooth={true}>Chewie Coin</Link></MainText>
            <LinkHolder>
                <NavLink><Link to="meet" offset={-50} smooth={true}>Meet Chewie</Link></NavLink>
                <Paw src={paw} alt="paw"/>
                <NavLink><Link to="buy" offset={-50} smooth={true}>How to Buy</Link></NavLink>
                <Paw src={paw} alt="paw"/>
                <NavLink><Link to="chewiecoin" offset={-75} smooth={true}>What is Chewie Coin?</Link></NavLink>
                <Paw src={paw} alt="paw"/>
                <NavLink><Link to="community" offset={-50} smooth={true}>Our Community</Link></NavLink>
                <Paw src={paw} alt="paw"/>
            </LinkHolder>
            <HamburgerHolder>
            <HamburgerButton  onClick={() => {
                    hamburger === '☰' ? setHamburger("X") : setHamburger("☰");
                    }}>{hamburger}</HamburgerButton>
            {hamburger === 'X' ? 
                <Hamburger> 
                    <HamburgerLinkHolder>
                        <PawHamburger src={paw} alt="paw"/>
                        <HamburgerLink>
                            <Link to="community" offset={-50} smooth={true} onClick={() => {
                                hamburger === '☰' ? setHamburger("X") : setHamburger("☰");
                                }}>Our Community
                            </Link>
                        </HamburgerLink> 
                    </HamburgerLinkHolder>
                    
                    <HamburgerLinkHolder>
                        <PawHamburger src={paw} alt="paw"/>
                        <HamburgerLink>
                            <Link to="chewiecoin" offset={-75} smooth={true} onClick={() => {
                                hamburger === '☰' ? setHamburger("X") : setHamburger("☰");
                                }}>What is Chewie Coin?
                            </Link>
                        </HamburgerLink> 
                    </HamburgerLinkHolder>

                    <HamburgerLinkHolder>
                        <PawHamburger src={paw} alt="paw"/>
                        <HamburgerLink>
                            <Link to="buy" offset={-50} smooth={true} onClick={() => {
                                hamburger === '☰' ? setHamburger("X") : setHamburger("☰");
                                }}>How to Buy
                            </Link>
                        </HamburgerLink> 
                    </HamburgerLinkHolder>

                    <HamburgerLinkHolder>
                        <PawHamburger src={paw} alt="paw"/>
                        <HamburgerLink>
                            <Link to="meet" offset={-50} smooth={true} onClick={() => {
                                hamburger === '☰' ? setHamburger("X") : setHamburger("☰");
                                }}>Meet Chewie
                            </Link>
                        </HamburgerLink> 
                    </HamburgerLinkHolder>
                </Hamburger> 
                : null}
            </HamburgerHolder>
        </Background>
    );
};

export default Navigation;